* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Slab', serif;
  letter-spacing: 1px;
  overscroll-behavior: none;
}

.break{
  flex-basis: 100%;
  height: 0;
}

.zoom-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.divider{
  display: flex;
  width: 100%;
  background-color: #001e00;
  height: 1px;
}

.header_underline_2{
  display: flex;
  height: 2.5px;
  width: 360px;
  background-color: #001e00;
  margin: 5px 0 15px 0;
  border-radius: 3px;
}

.header_underline_1{
  display: flex;
  height: 1.5px;
  width: 210px;
  background-color: #fff;
  margin: 5px 0 10px 0;
  border-radius: 3px;
}

.page_loading{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

h1 {
  text-align: center;
  font-size: 30px;
  letter-spacing: 8px;
  margin-right: -8px;
}

h2 {
  text-align: center;
  font-size: x-large;
  letter-spacing: 5px;
  margin-right: -5px;
}

h3 {
  text-align: center;
  font-size: large;
  letter-spacing: 3px;
  margin-right: -3px;
}

p {
  font-size: 16px;
  text-align: justify;
  text-align-last: center;
}

span {
  font-size: 16px;
  text-align: justify;
  text-align-last: center;
}

button{
  font-size: 17px;
}

abbr{
  font-size: 14px;
}

.shake {
  animation: wiggle 2s linear;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@media screen and (max-width: 700px) {

  h1 {
    font-size: 28px;
    letter-spacing: 6px;
    margin-right: -6px;
  }

  h2 {
    font-size: 22px;
    letter-spacing: 5px;
    margin-right: -5px;
  }

  h3 {
    font-size: 17px;
  }

  p {
    font-size: medium;
    text-align: justify;
    text-align-last: center;
  }
  
  span {
    font-size: medium;
    text-align: justify;
    text-align-last: center;
  }

}

@media screen and (max-width: 500px) {

  h1 {
    font-size: 24px;
    letter-spacing: 5px;
    margin-right: -5px;
  }
}

@media screen and (max-width: 400px) {

  .header_underline_2{
    width: 325px;

  }

}

@media screen and (max-width: 350px) {

  .header_underline_2{
    width: 310px;
  }

}