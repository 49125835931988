.navbar {
  background: #001e00;
  height: 100px;
  display: flex;
  position: sticky;
  top: 0px;
  justify-content: center;
  z-index:3;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100px;
  width: 100%;  
}

.navbar-logo{
  background: url('../images/chatterslogo-white.png') center center/cover;
  height: 60px;
  width: 110px;
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  align-self: center;
  justify-content: center;
  object-fit: contain;
}

.navbar-title{
  display: flex;
  font-family: 'Gill Sans Cn';
  color: #fff;
  font-weight: 500;
  font-size: 48px;
  letter-spacing: 16px;
  margin-right: -16px;
  align-self: center;
  text-decoration: none;
}

.navbar-title-container {
  cursor: pointer;
  text-align: center;
  display: grid;
  align-items: center;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  width: 100%;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
  list-style-type: none;
}

.nav-menu.active {
  background: #001e00;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  list-style-type: none;
}

.nav-item {
  height: 20px;
  align-self: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  margin-bottom: 30px;
}

.nav-links {
  color: #fff;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, auto);
  height: 100%;
  font-size: 14px;
  letter-spacing: 2.5px;
  margin-right: -2.5px;
  justify-content: center;
  align-self: center;
  justify-self: center;
  text-decoration: none;
  list-style-type: none
}

.nav-links:hover {
  border-bottom: 1px solid #fff;
  transition: line 1.25s ease forwards;
}

.menu-btn{
  display: flex;
  position: absolute;
  top: 28px;
  right: 10px; 
  justify-content: center;
  align-items: center;
  width:40px;
  height:50px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  overflow: hidden;
}

.menu-btn__burger{
  width:28px;
  height:3.5px;
  background: #fff;
  border-radius: 1.6px;
  transition: all .5s ease-in-out;
  }

  .menu-btn__burger::before,
  .menu-btn__burger::after{
    content: '';
    position: absolute;
    width: 28px;
    height: 3.5px;
    background: #fff;
    border-radius: 1.6px;
    transition: all .5s ease-in-out;
  }

  .menu-btn__burger::before{
    transform: translateY(-9px);
  }

  .menu-btn__burger::after{
    transform: translateY(9px);
  }

  /* Animation hamburger*/
  .menu-btn.open .menu-btn__burger {
    transform: translateX(50px);
    background: transparent;
  }

  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(-35px, 35px);
  }

  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(-35px, -35px);
  }

  .basket_icon{
    display: flex;
    position: fixed;
    z-index: 4;
    height: 43px;
    width: 39px;
    top: 26px;
    right: 56px;
    cursor: pointer;
  }
  
  .basket_size{
    display: flex;
    position: fixed;
    color: #fff;
    font-family: Sono;
    letter-spacing: -0.5px;
    font-weight: 700;
    font-size: 15px;
    z-index: 4;
    top: 24px;
    right: 50px;
    height: 15px;
    width: 15px;
    text-align: center;
    cursor: pointer;
  }


@media screen and (max-width: 700px) {
  .navbar {
    height: 81px;
  }

  .nav-menu{
    padding-top: 0;
    height: 190px;
  }

  .navbar-logo {
    height: 50px;
    width: 90px;
    top: 15px;
    left: 10px;
  }

  .navbar-title{
    font-size: 38px;
    letter-spacing: 12px;
    margin-right: -12px;
  }

  .menu-btn{
    top: 19px;
    right: 2px;
  }

  .menu-btn__burger{
    width:24px;
    }
  
  .menu-btn__burger::before,
  .menu-btn__burger::after{
    width: 24px;
  }

  .menu-btn__burger::before{
    transform: translateY(-8px);
  }

  .menu-btn__burger::after{
    transform: translateY(8px);
  }

  .basket_icon {
    top: 19px;
    right: 43px;
    height: 39.5px;
    width: 35px;
  }

  .basket_size{
    font-size: 14px;
    top: 17px;
    right: 36px;
  }
}

@media screen and (max-width: 500px) {
  .navbar-logo {
    height: 40px;
    width: 70px;
    top: 20px;
  }
}

@media screen and (max-width: 400px) {
  .navbar-logo {
    height: 32px;
    width: 60px;
    top: 25px;
  }

  .navbar-title{
    margin-bottom: 3px;
  }

}

@media screen and (max-width: 350px) {

  .navbar-title{
    font-size: 36px;
    letter-spacing: 10px;
    margin-right: -10px;
  }

}

@font-face {
  font-family: "Gill Sans Cn";   /*Can be any text*/
  src: local("Gill Sans Nova Cn"),
    url("../fonts/Gill Sans Nova Cn Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gill Sans Cn Light";   /*Can be any text*/
  src: local("Gill Sans Nova Cn Light"),
    url("../fonts/Gill Sans Nova Cn Light.ttf") format("truetype");
}
