.img_carousel_title_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .img_carousel_title_container h1 {
    align-self: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    letter-spacing: 14px;
    margin-right: -14px;
    width: 100%;
    color: #fff;
    text-shadow:2px 2px 6px black;
  }

  .hero_container {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: #fff;
  }

  .img_container {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 100px;
    scroll-behavior: smooth;
    align-self: center;
    justify-content: center;
  }

  @media screen and (max-width: 1080px) {
    .hero_container{
      height: 375px;
    }
  }
  
  @media screen and (max-width: 820px) {
    .hero_container{
      height: 300px;
    }
    
    .img_container {
      background-size: contain;
      background-position: top 0px;
    }
    
    .img_carousel_title_container h1 {
      font-size: 36px;
      letter-spacing: 10px;
      margin-right: -10px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 660px) {
    .hero_container{
      height: 260px;
    }
    
  }
  
  @media screen and (max-width: 410px) {
    
    .img_carousel_title_container h1 {
      font-size: 32px;
      letter-spacing: 9px;
      margin-right: -9px;
      overflow: hidden;
    }

    .img_container {
      background-size: contain;
      background-position: top 0px;
      top: -215px;
    }
  
  }

  @media screen and (max-width: 380px) {

    .img_container {
      background-size: contain;
      background-position: top 0px;
      top: -225px;
    }
    
    .header-container{
      height: 235px;
    }
  
  }
