.header-container {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}

.img-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  scroll-behavior: smooth;
  align-self: center;
  justify-content: center;
  background-position: center;
}

.header-txt {
  align-self: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: 14px;
  margin-right: -14px;
  width: 100%;
  color: #fff;
  text-shadow:2px 2px 6px black;
}

@media screen and (max-width: 1080px) {
  .header-container{
    height: 375px;
  }
}

@media screen and (max-width: 820px) {
  .header-container{
    height: 300px;
  }
  
  .img-container {
    background-size: contain;
    background-position: top 0px;
  }
  
  .header-container > h1 {
    font-size: 36px;
    letter-spacing: 10px;
    margin-right: -10px;
    overflow: hidden;
  }
}

@media screen and (max-width: 660px) {
  .header-container{
    height: 260px;
  }
  
}

@media screen and (max-width: 500px) {
  
  .header-txt {
    font-size: 32px;
    letter-spacing: 9px;
    margin-right: -9px;
    overflow: hidden;
  }

  .img-container {
    background-size: contain;
    background-position: top 0px;
  }

}

@media screen and (max-width: 380px) {

  .img-container {
    background-size: contain;
    background-position: top 0px;
  }
  
  .header-container{
    height: 235px;
  }

}
