.opening_times{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    overflow: hidden;
}

.opening_times > h2 {
    color: #001e00;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 40px;
}

.limited_txt {
    color: #001e00;
    align-items: center;
    text-align: justify;
    text-align-last: center;
    width: 80%;
    max-width: 800px;
    line-height: 32px;
    margin-bottom: 15px;
}

.key_container{
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    /* width: 240px; */
}

.key_container span {
    font-size: 15px;
}

.key_item{
    display: flex;
    align-items: center;    
    margin: 10px;
}

.key_txt{
    display: flex;
    align-self: center;
    margin-left: 10px;
}

.color_key {
    display: flex;
    align-self: center;
    height: 30px;
    width: 30px;
    background-color: #001e00;
}


.react_calendar { 
    width: 85%;
    max-width: 800px;
    background-color: #fff;
    color: #001e00;
    border-radius: 8px;
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17); */
    line-height: 1.125em;
    margin-bottom: 30px;
    margin-top: 10px;
}

.react-calendar__month-view__days__day--weekend {
    color: #001e00;
}

.react-calendar__tile {
    color: #001e00;
 }

.highlight {
    background-color:#001e00 !important;
    color: #fff !important;
    pointer-events: none;
}

.highlightCatering {
    background-color:#5b3c1e !important;
    color: #fff !important;
    pointer-events: none;
}

.full {
    background-color:#001e00 !important;
    opacity: 0.4;
    color: #fff !important;
    pointer-events: none;
    text-decoration: line-through;
}

.react-calendar__tile--now {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
}

 .react-calendar__navigation__label__labelText {
    color: #001e00;
    font-size: 15px;
    font-weight: bold;
 }

 .react-calendar button:enabled:hover {
    cursor: default;
    background-color: inherit;

  }

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus  {
    background: inherit;
    color: #001e00;
    background-color: #fff;
    cursor: default;
  }

.react-calendar__navigation__next-button{
    width: 70px;
    font-size: 25px;
    color: #001e00;
}

.react-calendar__navigation__prev-button{
    width: 70px;
    font-size: 25px;
    color: #001e00;
}

  @media screen and (max-width: 1045px) {

    .key_container span{
        font-size: 14px;
    }
}

@media screen and (max-width: 550px) {

    .key_container{
        flex-wrap: wrap;
        align-self: center;
        /* width: 240px; */
    }

    .key_color{
        height: 25px;
        width: 25px;
    }

    .key_item{
        margin: 5px;
    }

}

@media screen and (max-width: 470px) {

    .break_keys{
        flex-basis: 100%;
        height: 0;
    }

    .color_key.one{
        margin-left: 26px;
    }


}



   