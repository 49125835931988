
h1 {
  text-align: center;
  color: #001e00;
  font-weight: normal;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
  background: #fff;
}

.cards__wrapper {
  position: relative;
}

.cards__items_three{
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin-bottom: 5px;
  justify-content: center;
}

.cards__items {
  display: grid;
  grid-template-columns: repeat(4, auto);
  margin-bottom: 5px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0.8rem;
  height: 270px;
  width: 290px;
  /* box-shadow: 0 4px 15px rgba(56, 125, 255, 0.17); */
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__link:hover {
  transform: scale(1.05);
}

.cards__item__info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.cards__item__text {
  font-weight: 700;
  color: white;
  line-height: 30px;
  text-shadow:1px 1px 2px black;
  text-align: center;
  margin: 0 5px 0 5px;
}

@media only screen and (max-width: 1400px) {
  .cards__items {
    grid-template-columns: repeat(2, auto);
  }

}


@media only screen and (max-width: 1100px){
  .cards__items_three{
    grid-template-columns: repeat(2, auto);
  }
}

@media only screen and (max-width: 700px) {

  .cards__item{
    aspect-ratio: 1/1;
    height: auto;
    width: 44vw;
    margin: 10px;
  }
}

@media screen and (max-width: 500px) {

  .cards__item{
    width: 45vw;
    margin: 6px;
  }

}


