.sub_sec_container{
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    margin: 50px 100px 50px 100px;
    max-width: 1200px;
}


.sub_sec_item_img{
    display: flex;
    justify-self: center;
    width: auto;
    height: auto;
    max-width: 500px;
    max-height: 450px;
    object-fit: contain;
    margin-right: 50px;
    margin-left: 50px;
    border-radius: 6px;
    /* box-shadow: 0 4px 15px rgba(56, 125, 255, 0.17); */
}

.sub_sec_item_container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #001e00;
    font-style: normal;
    line-height: 32px;
    width: 100%;
    flex-direction: column;
}

.sub_sec_item_title{
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    max-width: 350px;
}

.sub_sec_item_txt{
    display: flex;
    align-items: center;
    font-style: normal;
    text-align: justify;
    text-align-last: center;
    justify-content: center;
    line-height: 32px;
    width: fit-content;
    margin: 0 20px 0 20px;
    flex-wrap: wrap;
}

.span_item_txt{
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
    font-style: normal;
    text-align: justify;
    text-align-last: center;
}

.go_to_btn{
    display: flex;
    align-items: center;
    padding: 8px;
    height: 32px;
    text-align: center;
    font-weight: 700;
    letter-spacing:3px;
    font-size: 15px;
    border-radius: 6px;
    margin-top: 40px;
    border: 1px solid #001e00;
    background-color: #fff;
    color: #001e00;
    /* box-shadow: 0 4px 15px rgba(56, 125, 255, 0.17); */
  }
  
  .go_to_btn:hover{
    background-color: #001e00;
    color: #fff;
    cursor: pointer;
  }

@media screen and (max-width: 1250px) {

    .sub_sec_container{
        flex-wrap: wrap;
        gap: 30px;
        margin-top: 30px;
        margin-bottom: 10px;
        margin: 40px 0px 40px 0px
    }

    .sub_sec_item_img{
        max-width: 480px;
        max-height: 400px;
    }

    .sub_sec_item_container{
        max-width: 800px;
        width: 80%;
    }

    .go_to_btn{
        margin-top: 20px;
    }

}

@media screen and (max-width: 750px) {

    .sub_sec_item_container{
        width: 95%;
    }
}

@media screen and (max-width: 550px) {

    .sub_sec_item_img{
        max-width: 340px;
    }

}

@media only screen and (max-width: 450px) {

    .sub_sec_item_img{
        width: auto;
        height: auto;
        max-width: 85%;
    }
    
}