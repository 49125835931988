.catering_body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    background: #fff;
    position: relative;
    flex-direction: column;
  }

  .catering_quote_container{
    width: 100%;
    background-color: #001e00;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 30px 0;
    padding-top: 70px;
    padding-bottom: 70px;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .catering_quote{
    color: #fff;
    font-size: 21px;
    text-align: center;
    font-style: italic;
    width: 80%;
  }

  .chatters_van_icon{
    height: auto;
    width: auto;
    max-width: 320px;
  }

  .triple_img_container {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 20px;
    margin: 40px 30px 40px 30px;
    align-items: center;
    justify-items: center;
    max-width: 1400px;
  }

  .triple_img{
    width: auto;
    height: auto;
    max-width: 95%;
    border-radius: 5px;
    object-fit: contain;
  }

  .catering_txt{
    margin: 30px 0 0 0;
    height: auto;
    width: 85%;
    color: #001e00;
    text-align: justify;
    text-align-last: center;
    line-height: 32px;
    max-width: 800px;
  }

  .wedding_title{
    align-self: center;
    justify-content: center;
    display: flex;
    color: #001e00;
    margin-top: 20px;
    max-width: 350px;
    text-align: center;
  }


  @media screen and (max-width: 1045px) {

    .catering_quote{
      font-size: 18px;
    }
  }

  @media screen and (max-width: 700px) {

    .triple_img_container {
      display: grid;
      grid-template-columns: repeat(1, auto);
      gap: 20px;
      margin: 25px;
    }
    
  }

  @media screen and (max-width: 500px) {

    .catering_txt:last-of-type{
      text-align: center;
    }

  }

  @media screen and (max-width: 400px) {

    .chatters_van_icon{
      height: auto;
      width: auto;
      max-width: 300px;
    }
    
  }