.carousel_scratch {
    background-color: #001e00;
    width: 100%;
    height: 420px;
    margin-top: 50px;
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
}

.carousel-container{
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 80%;
}

.reviews_title{
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  margin-top: 20px;
  max-width: 350px;
  text-align: center;
}

.carousel-item{
    min-width: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39,0.575, 0.565, 1);
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    height: 300px;
    color: #fff;
    background-color: #001e00;
    padding: 0 5px 5px 5px;
}

.indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #001e00;
    margin-bottom: 15px
  }

  .indicators > div.inactive {
    opacity: 40%; 
  }
  
  .indicators > div.active {
    opacity: 100%; 
  }

@media only screen and (max-width:700px) {
  .carousel-item{
    padding: 0 5px 5px 5px;
}
  
}

@media only screen and (max-width: 550px) {

  .carousel-container{
    width: 95%;
  }

  .review_txt{
    font-size: 15px;
  }
  
}

@media only screen and (max-width: 380px) {

  .carousel-container{
    width: 95%;
  }

  .review_txt{
    font-size: 14px;
  }
  
}
