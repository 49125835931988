.footer-container {
  background-color: #001e00;
  padding: 3rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.footer-links {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-links span {
  color: #fff;
}

.footer-link-wrapper {
  display: flex;
  color: #fff;
  margin-bottom: 20px;
  gap: 220px;
}

.footer-link-wrapper h2 {
  font-weight: bold;
  color: #fff;
}

.footer-link-enquiries {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
}

.footer-link-find {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: no-wrap;
  line-height: 30px;
  color: #fff;
}

.footer-link-social {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 30px;
  margin-bottom: 15px;
}

.footer-link-social a {
  color: #fff;
  text-decoration: none;
}

.footer-link-social a:hover {
  transition: 0.3s ease-out;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
}

.social-media {
  width: 100%;
  display: flex;
  align-items: center;
}

.social-media-wrap {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  
}

.social-icons {
  display: flex;
  position: absolute;
  right: 40px;
  align-items: center;
  gap: 20px;
  margin-top: 2px;
}

.social-icons i {
  color: #fff;
  font-size: 24px;
}

.footer-logo {
  background: url('../images/chatterslogo-white.png') center center/cover;
  height: 40px;
  width: 70px;
  display: flex;
  position: absolute;
  left: 40px;
  align-self: center;
  object-fit: contain;
}

.website-rights {
  font-family: 'Gill Sans Cn Light';
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 15px;
  letter-spacing: 4px;
  margin-right: -4px;
}


@media screen and (max-width: 1200px) {
  .footer-link-wrapper{
    gap: 130px;
  }
}

@media screen and (max-width: 1020px) {
  .footer-link-wrapper{
    gap: 60px;
  }
}

@media screen and (max-width: 900px) {
  .footer-link-wrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }

  .footer-logo {
    height: 30px;
    width: 60px;
    left: 15px;
  }

  .social-icons {
    right: 15px;
  }

  .footer-link-find {
    margin-bottom: 10px;
  }

  .footer-link-enquiries {
    margin-bottom: 10px;
  }
}
