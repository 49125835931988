.menus {
    color: #001e00;
  }

  .menus_body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #fff;
    position: relative;
    flex-direction: column;
}

  .menus_txt {
    display: flex;
    margin: 0 30px 0 30px;
    height: auto;
    max-width: 800px;
    color: #001e00;
    text-align: justify;
    text-align-last: center;
    line-height: 35px;
    width: 80%;
  }

  @media screen and (max-width: 750px) {

    .menus_txt{
        width: 85%;
        margin-bottom: 20px;
    }
  }