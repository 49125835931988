.restaurant_body{
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    background: #fff;
    position: relative;
  }

.reviews_container{
  width: 80%;
  background: #001e00;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.review_txt{
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 21px;
  font-style: italic;
  line-height: 32px;
}

/* Removed for now */
.reviewers_name{
  display: flex;
  justify-content: center;
  text-align: center;
  color: white;
  margin-top: 25px;
  font-size: 18px;
  font-family:'Trebuchet MS';
  letter-spacing: 5px;
}

.triple_img_container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 20px;
  margin: 20px 30px 20px 30px;
}

.triple_img{
  width: auto;
  height: auto;
  max-width: 100%;
  border-radius: 5px;
  object-fit: contain;
}

.triple_img:hover {
  transform: scale(1.05);
}

.page_img_container{
  height: 450px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  scroll-behavior: smooth;
  position: fixed;
  top:200px;
  bottom:0px;
  left: 0px;
  right:0px;
  z-index: -1;
  margin-bottom: 70px;
  margin-top: 50px;
}


@media screen and (min-width: 1600px) {
  .page_img_container {
    height: 350px;
  }
}

@media screen and (max-width: 1000px) {
  .page_img_container{
    height: 265px;
  }

  .triple_img_container {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 20px;
    margin: 25px;
  }

  .review_txt{
    font-size: 18px;
    line-height: 28px;
  }

}


@media screen and (max-width: 750px) {
  .page_img_container{
    height: 265px;
  }

  .triple_img_container {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 20px;
    margin: 25px;
  }

  .review_txt{
    font-size: 16px;
  }

}

