.res_body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #fff;
    position: relative;
    margin-top: 35px;
    flex-direction: column;
}

  .telephone_img{
    display: flex;
    width: auto;
    height: auto;
    max-width: 450px;
    border-radius: 5px;
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .res_txt{
    display: flex;
    margin-bottom: 25px;
    line-height: 32px;
    text-align: justify;
    text-align-last: center;
    width: 80%;
    max-width: 800px;
  }

  @media screen and (max-width: 500px){
    .res_txt{
      width: 85%;
      margin-right: 30px;
      margin-left: 30px;
    }
  }
  

  @media screen and (max-width: 500px) {

    .telephone_img{
      max-width: 75%;
      align-content: center;
      justify-content: center;
      align-self: center;
      justify-self: center;
    }
    
  }